import { Component, ViewChild } from "@angular/core";
import { FormDeliveryInfo } from "@app/admin/base/delivery-info/comp";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";

@Component({
  selector: '[return-location]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class ReturnLocation extends BaseFormDialog1 {
  onOK: (data) => void = (data) => {}
  onCancel: () => void = () => {}
  @ViewChild('formDelivery') formDelivery: FormDeliveryInfo;
  get needUpdate() {return this.formDelivery?.needUpdate ?? false}

  dateTimeNow = new Date();
  
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  onBtnSave() {
    let data = this.formDelivery.formData_JSON(true);
    this.onOK(data);
    this.closeDialog();
  }

  onBtnCancel() {
    this.closeDialog();
    this.onCancel();
  }
}