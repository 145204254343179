import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DispatchService } from "../../dispatchService";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { DeliveryInfo } from "@wearewarp/types/data-model";
import { MasterData } from "@services/master.data";
import { TaskType } from "@wearewarp/types";
import { Const } from "@const/Const";

@Component({
  selector: 'display-address',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayAddress {
  public isLoading = true;
  public displayInfo: any = {};

  @Input() deliveryInfo: DeliveryInfo;
  @Input() type: TaskType

  ngOnChanges() {
    if (!this.deliveryInfo) return;
    this.displayInfo = {
      type: this.locationType(this.type),
      locationName: this.deliveryInfo.locationName,
      addressText: MasterData.getAddressText(this.deliveryInfo.addr),
      accessCode: this.deliveryInfo.accessCode,
      note: this.deliveryInfo.note,
      instructions: this.deliveryInfo.instructions
    }
  }

  private locationType(type: TaskType) {
    switch (type) {
      case Const.TaskType.PICKUP: return 'Pickup';
      case Const.TaskType.DROPOFF: return 'Dropoff';
      case Const.TaskType.RETURN_DEPOT:
      case Const.TaskType.RETURN: return 'Return';
      case Const.TaskType.TRANSIT: return 'Transit';
    }
  }

}