import { Component, Input } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { environment } from "@env/environment";
import { Utils } from "@services/utils";
import { Const } from "@const/Const";
import { Log } from "@services/log";

type DriverLinkType = 'web-app'|'native-app';

@Component({
  selector: "send-driver-sms",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class SendDriverSMS extends BaseComponent {

  @Input() token;
  @Input() driver;
  @Input() jobId;
  public driverLinkType: DriverLinkType = 'native-app';
  public driverUrl;
  
  ngOnInit(): void {
    super.ngOnInit();
    this.onDriverLinkTypeChanged();
  }

  onDriverLinkTypeChanged() {
    this.driverUrl = this.createDriverLink();
  }

  private createDriverLink() {
    if (!this.token) {
      return '';
    }
    if (this.driverLinkType == 'native-app') {
      const nativeAppLink = 'https://native-app.wearewarp.com/driver';    // TODO: server sẽ trả về URL trong master data
      return `${nativeAppLink}/${this.token}`;
    }
    if (this.token.length != 12) {
      /**
       * Legacy token
       * Trường hợp nếu là token dạng cũ, thì giữ nguyên URL với format cũ.
       * Chỉ áp dụng URL mới cho các task mới.
       */
      return `${environment.legacyDriverWebUrl}?token=${this.token}`;
    } else {
      return `${environment.driverWebUrl}/${this.token}`;
    }
  }

  private _smsConttent;
  public get smsContent(){
    if(this._smsConttent) return this._smsConttent;
    //default content
    return `${this.getFullName(this.driver)}, this is Warp, we want you to be safe. To simplify communication, can you please click the link so we know where you are tracking? We will need to call you if you don't click the link.`
  }
  public set smsContent(content){
    this._smsConttent = content;
  }
  public isSendingSMS = false;

  copyDriverUrl(elm: HTMLElement) {
    Utils.copyTextToClipboard(this.driverUrl, e => {
      if (e) {
        this.showErr('Cannot copy text to clipboard');
      } else {
        this.showSuccess('URL has already been copied to the clipboard');
        Utils.selectAll(elm);
      }
    })
  }

  onBtnSendSMS() {
    this.isSendingSMS = true;
    let text = this.smsContent;
    if (text) {
      text += '\n\n';
    }
    text += this.driverUrl;
    let params = {
      driverId: this.driver._id,
      phone: this.driver.phone,
      text: text,
    };
    let url = `${Const.APIURI_JOBS}/${this.jobId}/driver_sms`;
    this.api.POST(url, params).subscribe(
      resp => {
        Log.d('send driver sms done ', resp);
        this.isSendingSMS = false;
        this.showSuccess(`An SMS has been sent to ${this.getDriverName(this.driver)}.`);
      }, err => {
        this.showErr(err);
        this.isSendingSMS = false;
      }
    );
  }
  
}
