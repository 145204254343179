<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>{{headerText}}</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>
<div>
  <form nz-form class="form-detail" [formGroup]="formInput" nzLayout="vertical">
    <div>
      <nz-form-item>
        <nz-form-control>
          <form-location-settings formControlName="settings" (ngModelChange)="onSettingsChange($event)"></form-location-settings>
        </nz-form-control>
      </nz-form-item>
    </div>
  </form>
</div>