<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>Change Secondary Driver</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>
<div>
  <form nz-form [formGroup]="formInput">
    <div *ngFor="let key of ['driverId']">
      <div class="form-label-v2 bottom5">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
      <app-form-driver [formControlName]="key" [carrierId]="carrierId"></app-form-driver>
    </div>
    <!-- <div>
      <a (click)="onBtnCreateNewDriver()" class="btn-create-driver">
        <i nz-icon nzType="plus" style="margin-right: 5px;"></i>Create New Driver
      </a>
    </div> -->
    <div *ngFor="let key of ['reason']" class="top10">
      <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
      <textarea nz-input [formControlName]="key" [placeholder]="getPlaceHolder(key)"
        [nzAutosize]="{ minRows: 2, maxRows: 6 }">
      </textarea>
    </div>
  </form>
</div>

<div form-footer [onProgress]="onProgress" 
  [nzIconCancel]="null" [nzIconOK]="null"
  [canClickOK]="needUpdate && !onProgress"
  [canClickCancel]="!onProgress" labelOK="Update"
  (onOK)="onBtnSave()" (onCancel)="closeDialog()"></div>