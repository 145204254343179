import { Component, Input } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { TaskType } from "@wearewarp/types";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { DateUtil } from "@services/date-utils";
import { MasterData } from "@services/master.data";
import { FormDataActualTime } from "@wearewarp/types/rest-api/admin/form-data/dispatch";
import { TimeWindow } from "@wearewarp/types/data-model/types/TimeWindow";
import { Observable } from "rxjs";

@Component({
  selector: '[update-arrived-time]',
  templateUrl: './index.html',
  styleUrls: ['../../../../dialogs/dialogs.scss', '../../../../../styles/form-v2.scss']
})
export class UpdateArrivedTime extends BaseFormDialog1<FormDataActualTime> {

  @Input() type: TaskType;
  @Input() timezone: string = '';
  @Input() appointment: TimeWindow;
  @Input() onSave: (data: FormDataActualTime) => Observable<any>;
  @Input() onRefreshDetailJob: () => void;
  @Input() isConfirm: boolean = false;
  public shortTimezone;
  
  public static get declaration(): FormGroupDeclaration {return {
    arrived: {label: 'Arrived Time', type: 'dateTime', skipDiffSecond: true, required: true},
    departed: {label: 'Departed Time', type: 'dateTime', skipDiffSecond: true, required: true},
    delayCodeId: {label: 'Failure Code for delay'},
    note: {label: 'Note for delay'},
    confirmed: {label: ''}
  }}
  protected formGroupDeclaration: FormGroupDeclaration = UpdateArrivedTime.declaration;

  listDelayCodes:any = MasterData.getDelayCodes();
  isShowDelayCode = false;

  protected beforeBindModel(model: any) {
    if(model?.arrived === 'N/A') {
      model.arrived = null;
      this.formGroupDeclaration.arrived.readOnly = true;
    }
    if(model?.departed === 'N/A') {
      model.departed = null;
      this.formGroupDeclaration.departed.readOnly = true;
    }
    if (this.type == Const.TaskType.PICKUP) {
      this.listDelayCodes = this.listDelayCodes.filter((option) => option.type == Const.DelayCodeTypes.PICKUP) || [];
    }
    if (this.type == Const.TaskType.DROPOFF) {
      this.listDelayCodes = this.listDelayCodes.filter((option) => option.type == Const.DelayCodeTypes.DELIVERY) || [];
    }
    this.onCheckShowDelayCode(model);
    return model;
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  ngOnInit(): void {
    if (!this.timezone) {
      throw Error('timezone is required');
    }
    this.shortTimezone = DateUtil.timezoneStandardToUsShort(this.timezone);
    super.ngOnInit();
    this.oncheckRequiredDelayCode();
  }

  onBtnSave() {
    if (!this.needUpdate) {
      return;
    }
    let data: any = this.getFormData_JSON(true);
    if (!this.isShowDelayCode) {
      data.delayCodeId = null;
      data.note = null;
    }
    this.onProgress = true;
    this.onSave(data).subscribe(
      resp => {
        this.closeDialog();
        this.onProgress = false;
        this.onRefreshDetailJob();
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
    this.closeDialog();
  }

  onBtnCancel() {
    this.closeDialog();
  }

  onCheckShowDelayCode(params) {
    const fromTime = this.appointment?.from ? new Date(DateUtil.displayLocalTime(this.appointment.from, {timezone: this.timezone,format: "MM/DD/YY h:mm A",})).getTime() :  0;
    const toTime = this.appointment?.to ? new Date(DateUtil.displayLocalTime(this.appointment.to, {timezone: this.timezone,format: "MM/DD/YY h:mm A",})).getTime() : 0;
    // không có thời gian arrived thì lấy thời gian departed
    let arrivedTime = 0;
    if (params?.arrived) {
      const date = new Date(params?.arrived);
      date.setMilliseconds(0);
      date.setSeconds(0);
      date.getTime();
      arrivedTime = date.getTime();
    } else if (params?.departed) {
      const date = new Date(params?.departed);
      date.setMilliseconds(0);
      date.setSeconds(0);
      date.getTime();
      arrivedTime = date.getTime();
    }
    // const arrivedTime = params?.arrived ? new Date(params?.arrived).getTime() : new Date(params?.departed).getTime();
    if ((fromTime || toTime) && fromTime < arrivedTime && toTime < arrivedTime) {
      if (!this.isShowDelayCode) {
        this.isShowDelayCode = true;
        this.oncheckRequiredDelayCode();
      }
    } else if (this.isShowDelayCode) {
      this.isShowDelayCode = false;
      this.oncheckRequiredDelayCode();
    }
  }

  oncheckRequiredDelayCode() {
    let fc = <FormControl> this.formInput?.get('delayCodeId');
    if (fc) {
      if (this.isShowDelayCode) {
        fc.addValidators(Validators.required);
      } else {
        fc.removeValidators(Validators.required);
      }
      fc.updateValueAndValidity();
    }
  }
  onChange(value, key) {
    setTimeout(() => {
      switch(key) {
        case 'arrived':
        case 'departed':
          let data: any = this.getFormData_JSON(true);
          this.onCheckShowDelayCode(data);
        return 
      }
    }, 50);
  }
  
}
