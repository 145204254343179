<div class="form-header no-border no-padding-bottom">
  <div class="form-title f18b">Add Reason Code For Stop {{ stopIndex }}</div>
  <div class="top10 f14">Add reason code for the arrived.</div>
  <i class="ic-close clickable" (click)="onBtnCancel()" nz-icon nzType="close" nzTheme="outline"></i>
</div>

<form class="form-detail" [formGroup]="formInput" nz-form style="padding: 26px;">
  <div nz-row>
    <div nz-col [nzSpan]="24" *ngFor="let key of ['delayCodeId']">
      <div class="form-label-v2 single-line fix-height">
        {{getLabel(key)}}
        <span class="label-mark-required"></span>
      </div>
      <nz-form-item>
        <nz-form-control>
          <nz-input-group>
            <nz-select nzBackdrop="true" nzAllowClear nzShowSearch style="width: 100%;"
              [formControlName]="key"
              [nzPlaceHolder]="getPlaceHolder(key)"
            >
              <nz-option *ngFor="let item of listDelayCodes"
                [nzValue]="item?._id" [nzLabel]="item?.name">
              </nz-option>
            </nz-select>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>
    
    <div nz-col [nzSpan]="24" *ngFor="let key of ['delayNote']">
      <div class="form-label-v2 single-line fix-height">
        {{getLabel(key)}}
        <span *ngIf="isRequired('key') && getLabel('key').length > 0" class="label-mark-required"></span>
      </div>
      <nz-form-item>
        <nz-form-control>
          <nz-input-group>
            <textarea
              nz-input 
              [formControlName]="key"
              [nzAutosize]="{minRows: 2, maxRows: 4}">
            </textarea>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>

<div form-footer class="no-border"
  [canClickOK]="needUpdate" [canClickCancel]="true"
  nzIconOK="save" labelOK="Save" (onOK)="onBtnSave()"
  (onCancel)="onBtnCancel()"></div>
