<div style="padding: 24px;">
    <div class="center-vertical bottom10">
        <button nz-button nzType="default" (click)="closePopup()">
            Close
          </button>
        <h3 class="title">POD Issue</h3>
    </div>
    <div style="height: max-content;" *ngIf="podItem && isRender" notes-component
        [subjectId]="podItem._id" [subjectType]="'pod'"
        [allowDeletion]="true" [allowDeleteItemNotMine]="true"
        (fetchDataDone)="onNoteFetchingDone(podItem, $event)"
        (itemAdded)="onNoteItemAdded(podItem, $event)"
        (itemRemoved)="onNoteItemRemoved(podItem, $event)">
    </div>
</div>