<ng-template [nzModalTitle]>
  <div class="component-header center-vertical flex-space-between">
    <div class="center-vertical">
      <div class="modal-close" (click)="closeDialog()">
        <i nz-icon nzType="close"></i>
      </div>
      <div class="modal-title" style="margin-left: 12px;">{{job.code}} - Stops</div>
    </div>
    <div class="btn-action">
      <button nz-button nzType="default" (click)="closeDialog()">Close</button>
    </div>
  </div>
</ng-template>
<nz-skeleton [nzActive]="true" [nzLoading]="isLoading" [nzParagraph]="{rows: 12, width: ['100%','100%','100%','80%','100%','100%','100%','60%','100%','100%',]}">
  <div class="dispatch-route-detail">
    <div class="component-container">
      <div class="stop-list">
        <dispatch-route-stop
          *ngFor="let stop of this.displayInfo.stops"
          [stop]="stop"
          [issues]="displayInfo.issues"
          [isEnableUpdate]="false"
          [isShowActualTime]="false"
          [isExpandWithStatus]="true"
          [dispatchService2]="getDispatchService"
          [isEnableUpdateStatus]="false"
        ></dispatch-route-stop>
      </div>
    </div>
  </div>
</nz-skeleton>
