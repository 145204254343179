<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>Enter return location</div>
    <div class="flex">
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="onBtnCancel()"></span>
    </div>
  </div>
</ng-template>
<div>
  <form-delivery-info #formDelivery [model]="model" [defaultDateTime]="dateTimeNow"></form-delivery-info>
</div>
<ng-template [nzModalFooter]>
  <div>
    <div form-footer class="no-border"
      [canClickOK]="needUpdate" [canClickCancel]="true"
      nzIconOK="save" labelOK="Save" (onOK)="onBtnSave()"
      (onCancel)="onBtnCancel()"></div>
  </div>
</ng-template>
