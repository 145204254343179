<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>Add New Driver</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>
<div>
  <form nz-form [formGroup]="formInput">
    <ng-container *ngFor="let key of ['firstName', 'lastName', 'phone', 'isNotifyViaSMS']">
      <div *ngIf="key != 'isNotifyViaSMS'" class="form-label-v2">
        {{getLabel(key)}}
        <span *ngIf="isRequired(key)" class="label-mark-required"></span>
        <nz-form-item>
          <nz-form-control>
            <input nz-input [formControlName]="key"
              [type]="getInputType(key)"
              [placeholder]="getPlaceHolder(key)"
              (input)="onInputChanged($event, key)"
              (keypress)="onInputKeyPress($event, key)">
          </nz-form-control>
        </nz-form-item>
      </div>
      <div *ngIf="key == 'isNotifyViaSMS'">
        <div nz-checkbox [formControlName]="key">{{getLabel(key)}}</div>
      </div>
    </ng-container>
  </form>
</div>
<div form-footer class="no-border"
  [canClickOK]="needUpdate" 
  [canClickCancel]="!onProgress"
  [onProgress]="onProgress"
  [nzIconOK]="false" [nzIconCancel]="false" labelOK="Save" nzOkDanger="false" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()">
</div>