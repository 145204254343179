<div>
  <div>
    <nz-radio-group style="margin-top: 10px; margin-bottom: 20px;" class="select-driver-link"
      [(ngModel)]="driverLinkType"
      (ngModelChange)="onDriverLinkTypeChanged()">
      <label nz-radio nzValue="native-app" style="margin-bottom: 10px;">Force driver to use native iOS/Android app</label>
      <label nz-radio nzValue="web-app">Let driver to use legacy web app</label>
    </nz-radio-group>
  </div>
  <div class="flex">
    <div class="flex1 code-block">
      <span #pre>{{driverUrl}}</span>
      <i nz-icon nzType="copy" nzTheme="outline" class="copy clickable" (click)="copyDriverUrl(pre)"></i>
    </div>
    <button nz-button class="btn-send-sms" [nzLoading]="isSendingSMS" (click)="onBtnSendSMS()">Send SMS</button>
  </div>
  <div>
    <textarea nz-input class="msm-content"
      [nzAutosize]="{ minRows: 2, maxRows: 6 }"
      placeholder="SMS Content"
      [(ngModel)]="smsContent"
      [ngModelOptions]="{standalone: true}">
    </textarea>
  </div>
</div>