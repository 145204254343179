import { Component, Input } from "@angular/core";
import { TaskType } from "@wearewarp/types";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { MasterData } from "@services/master.data";
import { FormDataAddReasonCode } from "@wearewarp/types/rest-api/admin/form-data/dispatch";
import { Observable } from "rxjs";

@Component({
  selector: '[add-reason-code]',
  templateUrl: './index.html',
  styleUrls: ['../../../../dialogs/dialogs.scss', '../../../../../styles/form-v2.scss']
})
export class AddReasonCode extends BaseFormDialog1<FormDataAddReasonCode> {

  @Input() type: TaskType;
  @Input() stopIndex: number;
  @Input() onSave: (data: FormDataAddReasonCode) => Observable<any>;
  @Input() onRefreshDetailJob: () => void;
  
  public static get declaration(): FormGroupDeclaration {return {
    delayCodeId: {label: 'Failure Code for delay', required: true },
    delayNote: {label: 'Note for delay'},
  }}
  protected formGroupDeclaration: FormGroupDeclaration = AddReasonCode.declaration;

  listDelayCodes:any = MasterData.getDelayCodes();

  protected beforeBindModel(model: any) {
    if (this.type == Const.TaskType.DROPOFF) {
      this.listDelayCodes = this.listDelayCodes.filter((option) => option.type == Const.DelayCodeTypes.DELIVERY) || [];
    }
    return model;
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onBtnSave() {
    if (!this.needUpdate) return;
    let data: any = this.getFormData_JSON(true);
    this.onProgress = true;
    this.onSave(data).subscribe(
      resp => {
        this.closeDialog();
        this.onProgress = false;
        this.onRefreshDetailJob();
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
    this.closeDialog();
  }

  onBtnCancel() {
    this.closeDialog();
  }
}
