import { Component, OnInit, Input } from '@angular/core';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import RouteEntity from "../../entity/RouteEntity";
import { DispatchService } from "../../dispatchService";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: 'quick-preview',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class QuickPreview extends BaseFormDialog1 implements OnInit {
  public isLoading = true;
  public displayInfo: any = {};
  protected subscription: Subscription = new Subscription();
  protected route: RouteEntity;

  constructor(
    public activatedRoute: ActivatedRoute,
    private dispatchService: DispatchService,
  ) {
    super();
   }

  @Input() job: any;

  ngOnInit(): void {
    super.ngOnInit();
    this.getData();
  }

  private async getData() {
    const dispatchService = new DispatchService(this.api);
    await dispatchService.setRouteId(this.job.id ?? this.job._id).refresh();
    this.route = dispatchService.getRoute();
    this.buildDisplayInfo();
    this.isLoading = false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  private async buildDisplayInfo() {
    if (!this.route) return;
    const stops = this.route.getStops();
    const issues = this.route.getIssues();
    this.displayInfo = {
      stops: stops,
      issues
    }
  }

  get getDispatchService() {
    return this.dispatchService;
  }

}
