<ng-template [nzModalTitle]>
  <div class="component-header center-vertical flex-space-between">
    <div class="center-vertical">
      <div class="modal-close" (click)="closeDialog()">
        <i nz-icon nzType="close"></i>
      </div>
      <div class="modal-title" style="margin-left: 12px;">{{jobCode}} - Notes</div>
    </div>
  </div>
</ng-template>
<div *ngIf="isLoading" class="nodata" style="min-height: 400px;max-height: 400px"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div class="note-list" style="overflow-y: auto" *ngIf="!isLoading">
  <nz-empty *ngIf="pinnedItems.length==0 && getDateItems().length==0"></nz-empty>
  <div *ngIf="pinnedItems.length > 0" class="pinned-list">
    <div class="bottom5 flex">
      <div class="label">
        <span nz-icon nzType="pushpin" nzTheme="outline"></span>
        <span class="f13 left5">{{pinnedItems.length}} Pinned</span>
      </div>
    </div>
    <div class="group-content">
      <ng-container *ngFor="let item of pinnedItems" [ngTemplateOutlet]="noteItem"
        [ngTemplateOutletContext]="{item}"></ng-container>
    </div>
  </div>
  <div class="group-date bottom20" *ngFor="let date of getDateItems()">
    <div class="date-label">{{date}}</div>
    <div class="group-content">
      <ng-container *ngFor="let item of items[date]" [ngTemplateOutlet]="noteItem"
        [ngTemplateOutletContext]="{item}"></ng-container>
    </div>
  </div>
</div>

<ng-template #noteItem let-item="item" let-edittingId="edittingId">
  <div class="note-list-item">
    <div class="item-header">
      <div class="poster">
        <ng-container *ngIf="isExfreight(item)">
          <nz-avatar nzIcon="user" nzSrc="assets/img/exfreight.png"></nz-avatar>
        </ng-container>
        <ng-container *ngIf="!isExfreight(item)">
          <nz-avatar [nzText]="getCarrierName(item.carrier)?.slice(0,1) || getFullName(item.user).slice(0,1)" [ngStyle]="{ 'background-color': '#7265e6' }"></nz-avatar>
        </ng-container>
        <div class="info">
          <div class="name semibold">
            {{getCarrierName(item?.carrier) || getFullName(item.user) || 'No name'}}
            <span *ngIf="item.pinned?.when" nz-icon nzType="pushpin" nzTheme="fill" class="left10" style="color: #FAAD14;"></span>
          </div>
          <div class="time">{{item.time}}</div>
          <div class="top" style="width: 100%; margin-top: 5px;">
            <div style="line-height: 22px;word-wrap: break-word;" *ngIf="item.content && edittingId !== item.id" [innerText]="item.content"></div>
            <div *ngIf="item?.imageUploadFilesArr" class="list-images-upload">
              <div *ngFor="let image of item?.imageUploadFilesArr;let i = index">
                <img *ngIf="image" (click)="viewImageItem(image.imgUrl)" class="attached-image" [src]="image.imgUrl" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
