import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { TaskType } from "@wearewarp/types";
import { LocationSettings } from "@wearewarp/types/data-model";
import { Observable } from "rxjs";

@Component({
  selector: '[stop-settings]',
  templateUrl: './index.html',
  styleUrls: []
})
export class StopSettings extends BaseFormDialog1<{ settings: LocationSettings, type: TaskType}> {

  @Input() onSave: (data: { settings: LocationSettings}) => Observable<any>;
  @Input() onRefreshDetailOrder: () => void;
  @Input() headerText = 'Location Settings';

  protected formGroupDeclaration: FormGroupDeclaration = {
    settings: { label: 'Settings' }
  };

  constructor() {
    super();
  }

  protected beforeBindModel(model): any {
    return model;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  onSettingsChange(value) {
    this.onBtnSave();
  }

  onBtnSave() {
    let data = this.getFormData_JSON(true);
    this.onProgress = true;
    this.onSave(data).subscribe(
      resp => {
        this.onProgress = false;
        this.updateSuccess(resp);
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }
    
}
