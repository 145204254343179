<div class="form-header" style="border-bottom: 1px solid #ccc" [ngClass]="{'form-header-large': !isLocationLevel && shipment}">
  <div style="align-self: center;">
    <div class="box-title">
      <span class="form-title-main" style="display: block;">View POD</span>
      <span class="form-title-info" style="display: block;">Route {{ this.job?.code }}
        <span class="left15" style="display: inline-block;">Stop {{ this.stop?.index + 1 }}
          - {{ this.stop?.data?.type }}</span>
      </span>
      <span *ngIf="!isLocationLevel && shipment" class="form-title-info" style="display: block;">Shipment {{ showShipmentWarpId(shipment?.warpId) }}
      </span>
    </div>
  </div>
  <button nz-button (click)="closeDrawer()" class="right15" style="align-self: center;">Close</button>
</div>
<div class="form-body">
  <div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
  <div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

  <ng-container *ngIf="!isLoading && !isError">
    <div *ngFor="let podType of ['POD', 'BOL', 'Signature']" class="task-pod top15">
      <div *ngIf="shouldDisplay(podType)" class="bold pod-title" style="border-bottom: 1px solid #ccc" (click)="toggleCollapse(podType)">
        {{ podType }}
        <span *ngIf="isRequiredPod(podType)" class="label-mark-required">
          (Required)
        </span>
        <span *ngIf="!shouldExpand(podType)" style="float: right" nz-icon nzType="down"></span>
        <span *ngIf="shouldExpand(podType)" style="float: right" nz-icon nzType="up"></span>
      </div>

      <ng-container *ngIf="shouldExpand(podType) && shouldDisplay(podType)">
        <div class="pod-header top10" style="display: flex; justify-content: space-between; align-items: center;">
          <div style="margin-left: auto;">
            <button nz-button nzType="default" (click)="inputFile.click()"
                    [nzLoading]="podType == 'POD' ? isUploadingPOD : (podType == 'BOL' ? isUploadingBOL : isUploadingSignature)"
                    style="width: 100%">
              <i nz-icon nzType="plus" nzTheme="outline"></i>
              Add File
            </button>
            <input multiple #inputFile type="file" hidden accept=".pdf,.png,.jpg,.jpeg,.heic"
                   (change)="onFileSelected(inputFile.files, podType)"
                   [id]="'uploadRoute' + podType + 'Input'">
          </div>
        </div>
        <div *ngIf="getItems(podType).length == 0" class="bold"
             style="background: #f7f7f7; height: 50px; margin-top: 15px; margin-bottom: 15px; padding-top: 15px; text-align: center">
          No file added
        </div>
        <div *ngIf="getItems(podType).length > 0" class="pod-body">
          <div class="pod-item" *ngFor="let item of getItems(podType); let i = index">
            <div class="pod-content" style="display: flex">
              <div class="image">
                <ng-container *ngIf="getImages(podType).length">
                  <div ngxViewer [viewerOptions]="{ transition: true }">
                    <img *ngFor="let it of getImages(podType)" class="attached-pod"
                         [ngClass]="{ 'active': it.url === item.url && it._id === item._id}"
                         width="100px" [src]="it.url" alt=""/>
                  </div>
                </ng-container>
                <a (click)="viewPodItem(item)" *ngIf="item.isPdf">
                  <div class="attached-pod pdf overlay-container">
                    <canvas [id]="'task-' + firstTaskId + '-pod-' + i" width="100px"></canvas>
                  </div>
                </a>
              </div>
              <div class="info">
                <div class="info-text">
                  <div class="index">{{ i + 1 }}</div>
                  <div class="appled-tasks bold" [nz-popover] nzPopoverTrigger="click" nzPopoverTitle="Tasks"
                       [nzPopoverContent]="contentTemplate"
                       nzPopoverPlacement="left">
                    Applied for {{ item.countTasks }} tasks <span nz-icon nzType="edit" nzTheme="outline"></span>
                    <ng-template #contentTemplate>
                      <route-update-pod-tasks [tasks]="this.job?.tasks || []" [podItem]="item"
                                              [refreshData]="refreshPodChange"></route-update-pod-tasks>
                    </ng-template>
                  </div>
                </div>
                <div class="type" style="margin-top: 50px">
                  <nz-tag nzColor="red" *ngIf="getDuplicated(i, podType)">
                    Similar with #{{ getDuplicated(i, podType)?.index + 1 }}
                    ({{ (getDuplicated(i, podType).value * 100).toFixed(2) }}%)
                  </nz-tag>
                </div>
                <div class="action">
                  <div class="confirmed" *ngIf="isConfirmPOD(item)">
                    <nz-tag nzColor="success">
                      <i nz-icon nzType="check-circle" nzTheme="outline"></i>
                      <span class="text">Confirmed</span>
                    </nz-tag>
                    <button nz-button nzType="dashed" nzDanger (click)="onBtnUnConfirm(item)" nzSize="small" nz-tooltip
                            nzTooltipTitle="Un-confirm">
                      <span nz-icon nzType="rollback" nzTheme="outline"></span>
                    </button>
                  </div>
                  <div class="confirm" *ngIf="!isConfirmPOD(item)">
                    <button nz-button nzType="primary" (click)="onBtnConfirm(item)">Confirm</button>
                  </div>
                  <div class="delete" style="display: flex">
                    <button nz-button nzType="default" (click)="onBtnDownload(item)" nz-tooltip
                            nzTooltipTitle="Download POD" nzSize="small" style="border: none"
                            [nzLoading]="item?.isDownloading">
                      <img src="/assets/img/dispatch-icons/sim_card_download.svg"/>
                    </button>
                    <button nz-button nzType="default" (click)="onBtnDelete(item)" nz-tooltip
                            nzTooltipTitle="Delete POD" nzSize="small"
                            style="border: none">
                      <img src="/assets/img/dispatch-icons/delete.svg"/>
                    </button>
                  </div>
                </div>
                <a class="hasIssue top10" (click)="openNoteIsuse(item)">
                  {{ item.isHasIssue ? 'Has issue' : 'Report issue' }}
                  <img *ngIf="item.isHasIssue" src="/assets/img/emergency_home.svg" />
                </a>
              </div>
            </div>
            <div class="info-user top10">
              <div>Uploaded by
                <warp-when-by [model]="item?.insert" [viewTemplate]="tplPODWhenBy"></warp-when-by>{{ formatDate(item.createdAt) }}
              </div>

              <div *ngIf="isConfirmPOD(item)">Confirmed by
                <warp-when-by [model]="item?.podConfirmed" [viewTemplate]="tplPODWhenBy"></warp-when-by>{{ formatDate(item?.podConfirmed?.when) }}
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <!--        POD Shipment Level-->
      <div *ngIf="!isLocationLevel && podType === 'POD' && shouldExpand('Shipment')">
        <div *ngFor="let ship of shipmentItems; let j = index">
          <div *ngIf="shouldDisplayShipment(ship)" class="pod-header top10" style="display: flex; justify-content: space-between; align-items: center;">
            <div *ngIf="!shipment" (click)="toggleCollapseShipment(ship)">
              <span class="bold pod-level">Shipment {{ showShipmentWarpId(ship?.warpId) }} <span
                *ngIf="isRequiredPod('POD')"
                class="label-mark-required">(Required)</span>
              </span>
                <span *ngIf="!shouldExpandShipment(ship)" style="float: right" nz-icon nzType="down"></span>
                <span *ngIf="shouldExpandShipment(ship)" style="float: right" nz-icon nzType="up"></span>
            </div>
            <div style="margin-left: auto;" >
              <button nz-button nzType="default" nzSize="small" (click)="inputFile.click()" [nzLoading]="ship?.isUploading"
                      style="width: 100%">
                <i nz-icon nzType="plus" nzTheme="outline"></i>
                Add File
              </button>
              <input multiple #inputFile type="file" hidden accept=".pdf,.png,.jpg,.jpeg,.heic"
                     (change)="onFileSelected(inputFile.files,'Photo', ship)"
                     [id]="'uploadRoute' + podType + 'Input'">
            </div>
          </div>
          <ng-container *ngIf="shouldExpandShipment(ship) && shouldDisplayShipment(ship)">
            <div *ngIf="hasDataPodShipmentLevel(ship.pods) === false" class="bold"
                 style="background: #f7f7f7; height: 50px; margin-top: 15px; margin-bottom: 15px; padding-top: 15px; text-align: center">
              No file added
            </div>
            <ng-container *ngIf="hasDataPodShipmentLevel(ship.pods) === true">
              <ng-container *ngFor="let item of getPodTypePhotoOfShipmentLevel(ship.pods); let i = index">
                <div class="pod-body">
                  <div class="pod-item">
                    <div class="pod-content" style="display: flex">
                      <div class="image">
                        <ng-container *ngIf="getImagesOfShipmentLevel(ship.pods).length && !item.isPdf">
                          <div ngxViewer [viewerOptions]="{ transition: true }">
                            <img *ngFor="let it of getImagesOfShipmentLevel(ship.pods)" class="attached-pod"
                                 [ngClass]="{ 'active': it.url === item.url && it._id === item._id}"
                                 width="100px" [src]="it.url" alt=""/>
                          </div>
                        </ng-container>
                        <a (click)="viewPodItem(item)" *ngIf="item.isPdf">
                          <div class="attached-pod pdf overlay-container">
                            <canvas [id]="'task-' + firstTaskId + '-pod-' + i" width="100px"></canvas>
                          </div>
                        </a>
                      </div>
                      <div class="info">
                        <div class="info-text">
                          <div class="index">{{ i + 1 }}</div>
                          <div class="appled-tasks bold" [nz-popover] nzPopoverTrigger="click" nzPopoverTitle="Tasks"
                               [nzPopoverContent]="contentTemplate"
                               nzPopoverPlacement="left">
                            Applied for {{ item.countTasks }} tasks <span nz-icon nzType="edit" nzTheme="outline"></span>
                            <ng-template #contentTemplate>
                              <route-update-pod-tasks [tasks]="this.job?.tasks || []" [podItem]="item"
                                                      [refreshData]="refreshPodChange"></route-update-pod-tasks>
                            </ng-template>
                          </div>
                        </div>
                        <div class="type" style="margin-top: 50px">
                          <nz-tag nzColor="red" *ngIf="getDuplicated(i, podType, ship)">
                            Similar with #{{ getDuplicated(i, podType, ship)?.index + 1 }}
                            ({{ (getDuplicated(i, podType, ship).value * 100).toFixed(2) }}%)
                          </nz-tag>
                        </div>
                        <div class="action">
                          <div class="confirmed" *ngIf="isConfirmPOD(item)">
                            <nz-tag nzColor="success">
                              <i nz-icon nzType="check-circle" nzTheme="outline"></i>
                              <span class="text">Confirmed</span>
                            </nz-tag>
                            <button nz-button nzType="dashed" nzDanger (click)="onBtnUnConfirm(item)" nzSize="small" nz-tooltip
                                    nzTooltipTitle="Un-confirm">
                              <span nz-icon nzType="rollback" nzTheme="outline"></span>
                            </button>
                          </div>
                          <div class="confirm" *ngIf="!isConfirmPOD(item)">
                            <button nz-button nzType="primary" (click)="onBtnConfirm(item)">Confirm</button>
                          </div>
                          <div class="delete" style="display: flex">
                            <button nz-button nzType="default" (click)="onBtnDownload(item)" nz-tooltip
                                    nzTooltipTitle="Download POD" nzSize="small" style="border: none"
                                    [nzLoading]="item?.isDownloading">
                              <img src="/assets/img/dispatch-icons/sim_card_download.svg"/>
                            </button>
                            <button nz-button nzType="default" (click)="onBtnDelete(item, ship)" nz-tooltip
                                    nzTooltipTitle="Delete POD" nzSize="small"
                                    style="border: none">
                              <img src="/assets/img/dispatch-icons/delete.svg"/>
                            </button>
                          </div>
                        </div>
                        <a class="hasIssue top10" (click)="openNoteIsuse(item)">
                          {{ item.isHasIssue ? 'Has issue' : 'Report issue' }}
                          <img *ngIf="item.isHasIssue" src="/assets/img/emergency_home.svg" />
                        </a>
                      </div>
                    </div>
                    <div class="info-user top10">
                      <div>Uploaded by
                        <warp-when-by [model]="item?.insert" [viewTemplate]="tplPODWhenBy"></warp-when-by>{{ formatDate(item.createdAt) }}
                      </div>
                      <div *ngIf="isConfirmPOD(item)">Confirmed by
                        <warp-when-by [model]="item?.podConfirmed" [viewTemplate]="tplPODWhenBy"></warp-when-by>{{ formatDate(item?.podConfirmed?.when) }}
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<pod-issue-popup class="pod-issue-component" [podItem]="podIssueItem" [ngClass]="{'active': podIssueShowing}"
                 (onClose)="onCloseNoteIsuseDrawer()"></pod-issue-popup>

<ng-template #tplPODWhenBy let-hasData="hasData" let-displayInfo="displayInfo">
  <span *ngIf="hasData" class="medium">
    <a [routerLink]="[displayInfo.hyperLinkCreatedBy]"
       style="color: #000000;"
       target="_blank">
      {{ displayInfo.createdBy }}
    </a>
  </span>
</ng-template>
