import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { ListJobItem } from "./";
import { UiCommonModule } from "@app/admin/components/common/module";
import { NzToolTipModule } from "ng-zorro-antd/tooltip"
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { CountdownTimerModule } from "../countdownTimer/module";
import { JobLiveIconComponent } from "../job-live-icon";
import { QuickPreview } from "../quick-preview";
import { SnoozeForm } from "../snooze";
import { SharedModule } from "@app/admin/shared/shared.module";
import { DetailModule } from "@app/admin/base/detail.module";
import { DispatchRoutingModule } from "../../dispatch.route";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzIconModule } from "ng-zorro-antd/icon";
import { DispatchRouteStop } from "../stop-item";
import { DisplayShipmentWarpID } from "../display-shipment-warpid";
import { DisplayAddress } from "../display-address";
import { DisplayAppointment } from "../display-appointment";
import { CollapseDirective } from "../../directives/collapse.directive";
import { DeliveryUpdateModule } from "@app/admin/components/delivery-update/module";
import { DispatchShipmentItem } from "../shipment-item";
import { NoteQuickPreview } from "../notes-quick-preview";
import { ModuleRating } from "@app/admin/components/rating/module";
import { CreateByUserModule } from "@app/admin/components/create-by-user/module";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzTableModule,
    NzPaginationModule,
    NzSelectModule,
    NzButtonModule,
    NzInputModule,
    NzIconModule,
    NzFormModule,
    SharedModule,
    UiCommonModule,
    CommonModule,
    NzAvatarModule,
    NzToolTipModule,
    CountdownTimerModule,
    NzBadgeModule,
    NzPopoverModule,
    UiCommonModule,
    DetailModule,
    DeliveryUpdateModule,
    ModuleRating,
    CreateByUserModule,
  ],
  declarations: [
    DisplayAppointment,
    CollapseDirective,
    DispatchRouteStop,
    DispatchShipmentItem,
    DisplayShipmentWarpID,
    DisplayAddress,
    SnoozeForm,
    QuickPreview,
    NoteQuickPreview,
    ListJobItem,
    JobLiveIconComponent,
  ],
  exports: [
    ListJobItem,
    DispatchRouteStop,
    DisplayAppointment,
    DisplayAddress
  ],
  providers: [
  ]
})
export class ListJobItemModule {
}
