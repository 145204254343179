<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>Initiate RETURN task for shipment {{displayInfo.shipmentWapIds}}</div>
    <div class="flex">
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>

<div>
  <nz-radio-group style="width: 100%;"
    [(ngModel)]="destinationType" 
    [ngModelOptions]="{standalone: true}" 
    (ngModelChange)="onDestinationTypeChange($event)">
    <div nz-radio nzValue="pick" class="radio-item">
      Return to the pickup address
      <div class="radio-sub-content" [ngClass]="{'hidden': destinationType!='pick'}">
        <b>{{displayInfo.pickAddr}}</b>
      </div>
    </div>
    <div nz-radio nzValue="other" class="radio-item other">
      Return to another address
      <div class="radio-sub-content" [ngClass]="{'hidden': destinationType!='other'}">
        <b (click)="editReturnInfo()">{{displayInfo.returnAddr}}</b>
      </div>
    </div>
  </nz-radio-group>

  <div class="top20">
    <div class="form-label-v2">Reason: <span class="label-mark-required"></span></div>
    <nz-form-item>
      <nz-form-control>
        <nz-select nzBackdrop="true" [(ngModel)]="returnReasonCode" style="width: 100%; margin-top: 8px;" nzPlaceHolder="Select">
          <nz-option *ngFor="let item of allReasonReturn" [nzLabel]="item.name" [nzValue]="item.code"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>

  <div class="top20" *ngIf="isRequireReasonRemark()">
    <div class="form-label-v2">Remark: <span class="label-mark-required"></span></div>
    <nz-form-item>
      <nz-form-control>
        <textarea nz-input style="width: 100%; margin-top: 8px;" class="ant-input" [(ngModel)]="returnReasonMessage"></textarea>
      </nz-form-control>
    </nz-form-item>
  </div>

</div>
<ng-template [nzModalFooter]>
  <div *ngIf="!isLoading" class="form-footer-init-return">
    <div form-footer [onProgress]="onProgress" 
    [nzIconCancel]="null" [nzIconOK]="null"
    [canClickOK]="needUpdate && !onProgress && !isLoading"
    [canClickCancel]="!onProgress" labelOK="Save"
    (onOK)="onBtnSave()" (onCancel)="closeDialog()"></div>
  </div>
</ng-template>