import { Component, Input } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { InputHelper } from "@services/input-helper";
import { FormDataAddNewDriver } from "@wearewarp/types/rest-api/admin/form-data/dispatch";
import { Const } from "@const/Const";

@Component({
  selector: 'add-new-driver',
  templateUrl: './view.html',
  styleUrls: ['./index.scss']
})
export class AddNewDriver extends BaseFormDialog1<FormDataAddNewDriver> {

  @Input() carrierId;

  protected formGroupDeclaration: FormGroupDeclaration = {
    firstName: {label: 'First name', required: true},
    lastName: {label: 'Last name', required: true},
    phone: {label: 'Phone', inputType: 'tel', required: true, getValue: InputHelper.getValuePhone, formatValue: InputHelper.formatPhone},
    isNotifyViaSMS: {label: 'Driver has opted in to receive text messages', type: 'boolean', initialValue: true }
  }

  ngOnInit(): void {
    if (!this.carrierId) {
      throw Error('Carrier must be provided');
    }
    super.ngOnInit();
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  onBtnSave() {
    if (!this.needUpdate) {
      return;
    }
    let data = this.getFormData_JSON(true);
    this.onProgress = true;
    data.carrierId = this.carrierId;
    this.api.POST(Const.APIURI_DRIVERS, data).subscribe(
      resp => {
        this.onProgress = false;
        this.updateSuccess(resp);
        this.showSuccess("You have successfully created new driver.");
        this.closeDialog();
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    )
  }
    
}
