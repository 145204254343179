import cv from "@techstark/opencv-js";

export class ImageService {
  static async compareImages(images) {
    const hists = await Promise.all(images.map(async (image) => {
      return await this.convertImageToHist(image);
    }))

    //compare hist of images
    const compare_result = [];
    for (let i = hists.length - 1; i > 0; i--) {
      compare_result[i] = [];
      for (let j = i - 1; j >= 0; j--) {

        if (!hists[i] || !hists[j]) {
          compare_result[i][j] = 0;
          continue;
        }
        const result = cv.compareHist(hists[i], hists[j], cv.HISTCMP_CORREL);
        compare_result[i][j] = result;
      }
    }
    return compare_result;
  }

  static async convertImageToHist(imageUrl) {
    if (!imageUrl) return null
    const image = new Image();
    image.src = imageUrl;
    image.setAttribute('crossOrigin', '');
    await new Promise<void>((resolve, reject) => {
      image.onload = () => {
        resolve()
      }
    })
    const mat = cv.imread(image);
    let srcVec = new cv.MatVector();
    srcVec.push_back(mat);

    let accumulate = false;
    let channels = [0];
    let histSize = [256];
    let ranges = [0, 255];
    let hist = new cv.Mat();
    let mask = new cv.Mat();

    cv.calcHist(srcVec, channels, mask, hist, histSize, ranges, accumulate);
    mat.delete(); srcVec.delete(); mask.delete();
    return hist;
  }

}