import { Component, Input, Output } from "@angular/core";

import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";

@Component({
    selector: 'snooze-form',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
    })
export class SnoozeForm extends BaseFormDialog1 {
    @Input() task: any;

    public onOk: (data) => void = () => {}

    constructor() {
        super();
    }

    preset = [

        { label: '1 Minute', value: 1 },
        { label: '5 Minute', value: 5 },
        { label: '10 Minute', value: 10 },
        { label: '15 Minute', value: 15 },
        { label: '20 Minute', value: 20 },
        { label: '30 Minute', value: 30 },
        { label: '45 Minute', value: 45 },
        { label: '1 Hour', value: 1 * 60 },
        { label: '2 Hours', value: 2 * 60 },
        { label: '4 Hours', value: 4 * 60 },
        { label: '6 Hours', value: 6 * 60 },
    ]

    
    protected formGroupDeclaration: FormGroupDeclaration = {
        hours: { label: 'Hours', placeHolder: 'hh'},
        minutes: { label: 'Minutes', placeHolder: 'mm'},
    }
    ngOnInit(): void {
        super.ngOnInit();
    }
    get IssueAlertMessage(): string {
        return this.task?.issues?.find(issue => issue.status === 'alert')?.message || '';
    }
    onBtnSave(): void {
        // this.setItemValue('hours', 0),
        // this.setItemValue('minutes', 0.1),
        this.updateSuccess(this.getFormData_JSON(true));
        this.closeDialog();
    }
    generateRange(start: number, end: number): number[] {
        return Array.from({ length: end - start + 1 }, (_, i) => start + i);
      }
    setPreset(value: number) {
        this.setItemValue('hours', Math.floor(value / 60));
        this.setItemValue('minutes', value % 60);
    }
}