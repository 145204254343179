import { Component, Input } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { FormDataChangeDriver } from "@wearewarp/types/rest-api/admin/form-data/dispatch";
import { DialogService } from "@dialogs/dialog.service";
import { AddNewDriver } from "../add-new-driver";
import { Const } from "@const/Const";
import { Log } from "@services/log";

@Component({
  selector: 'change-driver-v2',
  templateUrl: './view.html',
  styleUrls: ['./index.scss']
})
export class ChangeDriverV2 extends BaseFormDialog1<FormDataChangeDriver> {

  @Input() jobId;
  @Input() carrierId;
  @Input() driverId;
  @Input() secondaryDriverId;

  protected formGroupDeclaration: FormGroupDeclaration = {
    driverId: {label: 'Driver', required: true},
    reason: {label: 'Reason', required: true}
  }

  ngOnInit(): void {
    if (!this.carrierId) {
      throw Error('Carrier must be provided');
    }
    super.ngOnInit();
    if (this.driverId) {
      this.setItemValue('driverId', this.driverId);
    }
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  onBtnCreateNewDriver() {
    if (!this.carrierId) return;
    DialogService.openFormDialog1(AddNewDriver, {
      nzComponentParams: {
        carrierId: this.carrierId,
        closeOnSuccess: true,
        updateSuccess: resp => {
          let driver = resp.data;
          if (driver.id) {
            this.setItemValue('driverId',driver.id);
          }
        }
      },
      nzClassName: 'modal',
    });
  }

  private doAssignDriver(data) {
    this.setEnableFormGroup(false);
    this.startProgress();
    this.api.PUT(`${Const.APIV2(Const.APIURI_JOBS)}/${this.jobId}/assign_driver`, data).subscribe(
      (resp) => {
        Log.d("ChangeDriver done ", resp);
        this.updateSuccess(resp);
        this.stopProgress();
        this.closeDialog();
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
        this.setEnableFormGroup(true);
      }
    );
  }

  onBtnSave(): void {
    if (!this.needUpdate) {
      return;
    }
    let data = this.getFormData_JSON(true);
    const driverId = data?.driverId;
    if (driverId && this.secondaryDriverId == driverId) {
      this.confirmYesNo('This driver is currently a secondary driver, do you want to change to primary driver?', () => {
        this.doAssignDriver(data);
      });
    } else {
      this.doAssignDriver(data);
    }
    
    
  }

    
}
