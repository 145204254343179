import { Component, Input, OnInit } from "@angular/core";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { DateUtil } from "@services/date-utils";
import { Utils } from "@services/utils";
import {BaseFormDialog1} from '@dialogs/base-form-dlg1';

@Component({
  selector: "note-quick-preview",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class NoteQuickPreview extends BaseFormDialog1 implements OnInit {
  private _jobId: string;
  @Input() jobCode
  @Input() get jobId() {
    return this._jobId
  };
  set jobId(value) {
    this._jobId = value;
    if (value) {
      setTimeout(() => {
        this.fetchData();
      }, 100);
    }
  }

  items: any = {};
  pinnedItems = [];
  isLoading: boolean = true;

  formatData(data) {
    let items = {};
    let pinnedItems = [];
    for (let note of data) {
      if (Utils.isArrayNotEmpty(note?.imageUploadFilesArr)) {
        for (let item of note.imageUploadFilesArr) {
          item.imgUrl = this.attachedFileUrl(item);
        }
      }
      let date = note.insert?.when;

      if (note.pinned?.when && note.pinned?.by) {
        pinnedItems.push(note);
        note['time'] = DateUtil.dateToString(date, "MM/DD/YY hh:mm A");
        continue;
        //nếu note được pined thì đẩy lên đầu và không hiển thị lại ở phía dưới.
      }

      note['time'] = DateUtil.dateToString(date, "hh:mm A")


      //group note by day
      if (DateUtil.isToday(date)) date = "Today";
      else if (DateUtil.isYesterday(date)) date = "Yesterday";
      else date = DateUtil.dateToString(date, Const.FORMAT_GUI_DATE);


      if (!items[date]) items[date] = [];
      items[date].push(note);
    }
    this.pinnedItems = pinnedItems.sort(function (a, b) {
      let aDate = new Date(a.pinned.when);
      let bDate = new Date(b.pinned.when);
      return aDate.getTime() < bDate.getTime() ? 1 : (aDate.getTime() > bDate.getTime() ? -1 : 0)
    })
    return items;
  }

  fetchData() {
    this.isLoading = true;
    this.items = {};
    this.api.GET(`${Const.APIURI_CONVERSATIONS}/?subjectId=${this.jobId}&subjectType=job&type=note`).subscribe(
      (resp) => {
        const listData = resp?.data?.list_data || [];
        this.items = this.formatData(listData);
        this.isLoading = false;
      },
      (err) => {
        this.showErr(err);
        this.isLoading = false;
      }
    );
  }

  getDateItems() {
    return Object.keys(this.items);
  }

  viewImageItem(imgUrl) {
    if (!imgUrl) return;
    DialogService.previewImgs([imgUrl], 0);
  }

  public getCarrierName(carrier): string {
    let name = super.getCarrierName(carrier);
    if (name) name += ' (Carrier)';
    return name;
  }

  public isExfreight(note) {
    return note?.insert?.byCarrierId == Const.CarrierId_Exfreight;
  }
}
