import { Directive, Output, EventEmitter, ElementRef, HostListener, Input, Component } from '@angular/core';
import { AUTO_STYLE, animate, state, style, transition, trigger } from '@angular/animations';

const DEFAULT_DURATION = 300;

@Component({
  selector: '[collapse-animation]',
  styleUrls: [
    './collapse.scss',
  ],
  template: "<div [@expand]=\"expanded\"><ng-content></ng-content></div>",
  animations: [
    trigger('expand', [
      state('true', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('false', style({ height: '0', visibility: 'hidden' })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
    ])
  ]
})
export class CollapseDirective {
  public inited: boolean = false;
  private _expanded: boolean;
  @Input() get expanded() { return this._expanded };
  set expanded(value) {
    this._expanded = value;
    // setTimeout(() => {
    //   this.changeHeight()
    // }, 1);
  }
  el: ElementRef
  constructor(el: ElementRef) {
    this.el = el;
  }

  // changeHeight(): void {
  //   let dom: HTMLElement = this.el?.nativeElement;
  //   this.inited = true;
  //   if (!this._expanded) {
  //     dom.style.height = '0px';
  //     return;
  //   }

  //   let content = dom.getElementsByClassName("collapse-content")?.[0];
  //   if (!dom || !content) return;
  //   const contentHeight = content.clientHeight;
  //   dom.style.height = `${contentHeight}px`
  // }
}
