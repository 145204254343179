import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseDialog } from "@dialogs/base-dlg";
import { DialogService } from "@dialogs/dialog.service";
import { Log } from "@services/log";
import { ReturnLocation } from "./return-location";
import { Observable } from "rxjs";

@Component({
  selector: '[route-return]',
  templateUrl: './view.html',
  styleUrls: ['style.scss']
})
// 20230705 tạm thời xử lý 1 điểm pickup, có thời gian sẽ update trường hợp nhiều pickup khác nhau
export class RouteReturn extends BaseDialog {
  
  @Input() jobId;
  @Input() taskIds = [];
  @Input() shipmentWarpIds = [];
  @Input() onSave: (data) => Observable<any>;
  @Input() onSubmitSucceeded: (resp) => void;
  public tasks;
  public pickupInfo;
  public returnInfo;
  public displayInfo: any = {};
  public isLoading = true;
  public onProgress = false;
  destinationType: 'pick'|'other' = 'pick';
  returnReasonCode: string = '';
  returnReasonMessage: string = '';
  public allReasonReturn = Const.returnReason
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getData();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.onDestinationTypeChange(this.destinationType), 1)
  }

  private getData() {
    if (!this.jobId) {
      return Log.e("jobId is required");
    }
    let url = Const.APIV2(`${Const.APIURI_JOBS}/${this.jobId}/tasks`);
    this.api.GET(url).subscribe(
      (resp) => {
        this.tasks = resp.data?.list_data?.filter(item => this.taskIds.includes(item.id));
        let pickupTask = resp.data?.list_data?.find(item => item.type == Const.TaskType.PICKUP && item.shipmentId == this.tasks?.[0]?.shipmentId);
        if (pickupTask.info) {
          this.pickupInfo = pickupTask.info;
        } else {
          this.pickupInfo = {
            addr: pickupTask.address
          };
          if (!this.pickupInfo.addr.metadata) {
            this.pickupInfo.addr.metadata = pickupTask.location;
          }
        }
        this.buildDisplayInfo();
        this.isLoading = false;
      },
      (err) => {
        this.showErr(err);
        this.isLoading = false;
      }
    );
  }

  private buildDisplayInfo() {
    const firstTask = this.tasks?.[0];
    if (!firstTask) return;
    this.displayInfo = {
      pickAddr: this.getAddressText(this.pickupInfo?.addr),
      shipmentWapIds: this.shipmentWarpIds?.join(', ')
    }
  }

  private updateReturnInfo(newInfo){
    this.returnInfo = newInfo;
    this.displayInfo.returnAddr = this.getAddressText(newInfo?.addr);
  }

  onDestinationTypeChange(value) {
    switch (value) {
      case 'pick':
        break;
      case 'other':
        if (!this.returnInfo) {
          this.openLocationForm();
        }
        break;
    }
  }

  editReturnInfo() {
    this.openLocationForm();
  }

  private openLocationForm() {
    DialogService.openFormDialog1(ReturnLocation, {
      nzComponentParams: {
        model: this.returnInfo,
        onOK: data => {
          this.updateReturnInfo(data)
        },
        onCancel: () => {
          if (!this.returnInfo) {
            this.destinationType = 'pick';
          }
        }
      },
      nzClassName: 'modal-no-padding modal-dispatch-return-location',
    });
  }

  isRequireReasonRemark(){
    if(this.returnReasonCode === "Other Issue"){
      return true;
    }
    return false;
  }

  public get needUpdate(): boolean {
    if (!this.returnReasonCode) return false;
    // trường hợp là other thì phải yêu cầu nhập lý do (returnReasonMessage)
    if(this.isRequireReasonRemark() && !this.returnReasonMessage){
      return false;
    }
    return true;
  }

  public onBtnSave() {
    let data = {};
    data['info'] = this.destinationType == 'pick' ? this.pickupInfo : this.returnInfo;
    data['returnReason'] = {
      code: this.returnReasonCode,
      note: this.returnReasonMessage,
    };
    this.onProgress = true;
    this.onSave(data).subscribe(
      resp => {
        this.closeDialog();
        this.onProgress = false;
        this.onSubmitSucceeded(resp);
      }, err => {
        this.closeDialog();
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }
  
}
